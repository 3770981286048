//import "regenerator-runtime/runtime.js";

import "./scss/theme.scss";
import '@trullock/dollar'

import "./img/email/ukra-logo-email.png"
import "./img/wallet-hero.jpg"
import "./img/icon512_maskable.png"
import "./img/icon512_rounded.png"

import "./site.webmanifest";
import "./robots.txt"
import "./microsoft-identity-association.json"
import "./.well-known/apple-developer-merchantid-domain-association"

// because webpack is broken/shit.
import cerebriSans from './fonts/cerebrisans-regular.woff';
window.cerebriSansPath = cerebriSans;



import init from "./js/index.js";
init();