<script>
export const pageName = 'map-assessors-list';
export const pageRoute = '/model-achievement-programme/assessors';
export const title = 'MAP Assessors'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { functions } from '../../js/lib/functions.js';
export default {
	data() {
		return {
			assessors: [],
			ready: false
		}
	},

	methods: {
		async show() {
			this.assessors = await functions.listMAPAssessors();
			this.ready = true;
		},
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<h1 class="display-4 text-center mb-3">MAP Assessors</h1>
		<p class="text-muted text-center">MAP Assessors are volunteers who give up their time to help you with your MAP journey.</p>
		<p class="text-muted text-center">Below is a list of all active MAP Assessors to help you find them at your local club meet.</p>
		<p class="text-muted text-center mb-5">Please speak to your local club to understand who is likely to be present at any particular meet.</p>
			
		
		<div class="row justify-content-center">
			<div class="col col-12 col-lg-8 ">
				<div class="card">
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="assessor in assessors" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<div class="avatar avatar-sm">
											<img class="avatar-img rounded-circle" :src="assessor.avatar">
										</div>
									</div>
									<div class="col">
										<h4 class="mb-1">{{ assessor.name }}</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
