<script>
export const pageName = 'rockets-flight-add';
export const pageRoute = '/rockets/{rocketId}/flights/add';
export const title = 'Add Flight'
export const requireMembership = true;

import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import DateTime from '../../../functions/lib/dateTime.js';
import TomSelect from 'tom-select'
import { nextTick } from 'vue';
import { getMapTasksForFlightLog, getUserMap } from '../../js/utils/map.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getRocketView, getUserView } from '../../js/readModel.js';

export default {
	data() {
		return { 
			user: null,
			rocket: null,
			date: null,
			dateMax: DateTime.now.format('yyyy-MM-dd'),
			time: null,
			name: null,
			location: null,
			launchLatitude: null,
			launchLongitude: null,
			landingLatitude: null,
			landingLongitude: null,
			motors: null,
			delay: null,
			mass: null,
			sm: null,
			notes: null,
			outcome: '',
			mapTasks: [],
			flightLogs: [],
			flightLogError: null,
			mapFlight: false,
			flightCard: null,
			isDirty: false,
			ready: false
		}
	},

	methods: {
		beforeHide() {
			return this.isDirty ? 'Are you sure you want to abandon this new flight?' : null
		},
		async boot(opts) {

			this.rocket = await getRocketView(getCurrentUserId(), opts.rocketId);
			this.user = await getUserView(getCurrentUserId());

			this.modal = new Modal(this.$refs.cropper);

			this.rocketId = opts.rocketId;

			let map = await getUserMap(this.user);
			let allTasks = getMapTasksForFlightLog(map);

			this.ready = true;
			await nextTick();
			
			this.$mapTasks = new TomSelect(this.$refs.mapTasks,{
				options: allTasks,
				render: {
					option: function (data, escape) {
						return `<div><div class="ts-map-icon" style="background: url(${data.bg})"><img src="${data.icon}"></div>${data.text}</div>`;
					},
					item: function (item, escape) {
						return `<div><div class="ts-map-icon" style="background: url(${item.bg})"><img src="${item.icon}"></div>${item.text}</div>`;
					}
				},
				onItemAdd: function(){
					this.setTextboxValue('');
					this.clearOptions();

					let subjects = this.items.map(i => i.split('.')[0]);

					let newOptions = allTasks.filter(t => subjects.indexOf(t.subjectId) == -1 || t.subjectId == 'bonus')
					this.addOptions(newOptions);

					this.refreshOptions();
				},
				onItemRemove: function(){
					this.setTextboxValue('');
					this.clearOptions();

					let subjects = this.items.map(i => i.split('.')[0]);

					let newOptions = allTasks.filter(t => subjects.indexOf(t.subjectId) == -1 || t.subjectId == 'bonus')
					this.addOptions(newOptions);

					this.refreshOptions();
				},
				maxItems: null,
				create: false,
				plugins: {
					remove_button:{
						title:'Remove this item',
					}
				},
			});
		},
		show(opts) {
			functions.warmUp.rocketFlightAdd();

			this.date = DateTime.now.format('yyyy-MM-dd')
			this.time = DateTime.now.format('HH:mm')
			this.name = null;
			this.location = null
			this.launchLatitude = null
			this.launchLongitude = null
			this.motors = null
			this.delay = null
			this.mass = null
			this.sm = null
			this.notes = null
			this.flightLogs = [];
			this.flightLogError = null;
			this.isDirty = false;
			this.mapFlight = false;
			this.outcome = '';
			this.landingLatitude = null
			this.landingLongitude = null
			this.flightCard = null;
			this.mapTasks = [];
			this.$mapTasks.clear();
			this.$refs.fupFlightCard.value = null;
			this.$refs.lblFlightCard.innerHTML = "Choose image&hellip;";
			

			this.$refs.outcome.setCustomValidity('');
			this.$refs.outcome.reportValidity();

			this.$refs.fupFlightCard.setCustomValidity('');
			this.$refs.fupFlightCard.reportValidity();

			this.$refs.fupFlightLogs.setCustomValidity('');
			this.$refs.fupFlightLogs.reportValidity();

			this.$refs.mapTasks.setCustomValidity('');
			this.$refs.mapTasks.reportValidity();

		},
		geolocateLaunch() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.launchLatitude = position.coords.latitude;
					this.launchLongitude = position.coords.longitude;
				}, e => {
					console.error(e)
					showToast({
						message: 'Error geolocating you',
						style: 'bg-danger'
					})
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		geolocateLanding() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.landingLatitude = position.coords.latitude;
					this.landingLongitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		changed() {
			this.isDirty = true;
			this.mapFlight ? this.$mapTasks.enable() : this.$mapTasks.disable()
			this.$refs.fupFlightCard.disabled = !this.mapFlight
		},
		flightLogAdded(e) {
			var files = [...e.target.files];
			this.handleFiles(files);
		},
		handleFiles(files) {
			
			this.flightLogError = '';

			let filesProcessed = 0;

			var done = async (log, file) => {
				filesProcessed++;

				this.$refs.fupFlightLogs.value = null;

				let result = await functions.rocketFlightLogValidate({ log })

				if(filesProcessed == files.length)
					hideLoading();

				if(!result.valid)
				{	
					this.flightLogError += `Unsupported flight log '${file.name}'. If this is a valid flight log file please email it to membership@ukra.org.uk so we can investigate supporting it.`;
					return;
				}

				if(file.size > 5 * 1024 * 1024)
				{	
					this.flightLogError += `'${file.name}' is too large, maximum log size is 5MB.`;
					return;
				}

				this.flightLogs.push({ 
					log, 
					name: file.name,
					size: file.size
				});

				this.$refs.fupFlightLogs.value = null;
			};

			let error = (result, e) => {
				filesProcessed++;
				if(filesProcessed == files.length)
					hideLoading();

				console.error(e);
			}

			showLoading();

			files.forEach(f => {
				let reader = new FileReader();
				reader.onload = () => done(reader.result, f);
				reader.onerror = e => error(reader.result, e);

				if(f.type == 'application/octet-stream')
				{
					reader.readAsDataURL(f);
				}
				else if(f.type == 'text/csv')
				{
					reader.readAsText(f);
				}
				else
				{
					error('Unsupported file type')
				}
			});
		},
		removeLog(log) {
			this.flightLogs.splice(this.flightLogs.findIndex(l => l == log), 1);
		},
		dropped(e) {
			e.preventDefault();
			let files = [...e.dataTransfer.files];
			
			let dudFiles = files.filter(f => f.type != "text/csv");

			dudFiles.forEach(file => {
				this.$refs.fupFlightLogs.setCustomValidity(`Unsupported file type '${file.name}'.`);
				this.$refs.fupFlightLogs.reportValidity();
			})
			
			files = files.filter(f => f.type == "text/csv");

			if(files.length)
				this.handleFiles(files);
		},
		dragover(e) {
			e.preventDefault();
		},
		uploadClick(e) {
			this.$refs.fupFlightLogs.click();
		},
		async submit () {
			
			// we have to manually validate the file upload because `required` doesnt have good UX cross browser
			if(this.mapFlight && !this.flightCard)
			{
				this.$refs.fupFlightCard.setCustomValidity('Please provide an picture of your completed flight card');
				this.$refs.fupFlightCard.reportValidity();
				return;
			}

			// we have to manually validate the file upload because `required` doesnt have good UX cross browser
			if(this.mapFlight && this.outcome != 'positive')
			{
				this.$refs.outcome.setCustomValidity('Must have been a positive flight to pass MAP assessment');
				this.$refs.outcome.reportValidity();
				return;
			}

			showLoading();

			let flownOn = `${this.date} ${this.time}`;
			flownOn = new DateTime(flownOn)

			let result = await functions.rocketFlightAdd({ 
				rocketId: this.rocketId,
				flownOn,
				name: this.name,
				location: this.location,
				launchLatitude: parseFloat(this.launchLatitude || '0') || null,
				launchLongitude: parseFloat(this.launchLongitude || '0') || null,
				motors: this.motors,
				delay: parseFloat(this.delay || '0') || null,
				mass: parseInt(this.mass || '0', 10) || null,
				sm: parseInt(this.sm || '0', 10) || null,
				landingLatitude: parseFloat(this.landingLatitude || '0') || null,
				landingLongitude: parseFloat(this.landingLongitude || '0') || null,
				outcome: this.outcome,
				mapFlight: this.mapFlight,
				mapTasks: this.mapTasks,
				flightCard: this.flightCard,
				notes: this.notes
			});

			if(!result.success)
			{
				showToast({ message: 'Error adding flight', style: 'bg-danger'})
				hideLoading();
				return;
			}

			await Promise.all(this.flightLogs.map(log => functions.rocketFlightLogAttach({
				rocketId: this.rocketId,
				flightId: result.flightId,
				log: log.log,
				name: log.name	
			})))

			this.isDirty = false;

			navigate(`/rockets/${this.rocketId}`)

			showToast({
				message: 'Flight added',
				style: 'bg-success'
			})

			hideLoading();
		},
		crop() {
			showLoading();
			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
					
				let canvas = this.cropper.getCroppedCanvas({
					
				});

				this.flightCard = canvas.toDataURL();

				canvas.toBlob(blob => {
					this.$refs.fupFlightCard.blobData = {
						blob: blob,
						name: 'flight-card.jpg'
					};
					this.$refs.lblFlightCard.innerText = this.$refs.fupFlightCard.pendingName;
				});
				
				hideLoading();
			}, 1);
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		flightCardAdded(e) {
			this.$refs.fupFlightCard.setCustomValidity('');
			this.$refs.fupFlightCard.reportValidity();

			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupFlightCard.blobData = null;

				let $img = new Image();
				
				$img.addEventListener('load', e => {
					this.$refs.lblFlightCard.innerHTML = "Choose image&hellip;";
					this.$refs.fupFlightCard.pendingName = name;
					this.$refs.imgCropper.src = url;

					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'

					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						viewMode: 1,
						autoCropArea: 1
					});
				})
				$img.addEventListener('error', e => {
					this.$refs.fupFlightCard.setCustomValidity('File not a recognised image');
					this.$refs.fupFlightCard.reportValidity();
				})
				$img.src = url;
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Add Flight</h1>
				<p class="text-muted text-center mb-5">Record a new flight for your {{  this.rocket.name }}.</p>
					
				<form @submit.prevent="submit" @change="changed">				
					<h2>Flight</h2>
					<div class="form-group">
						<label class="form-label">Description</label>
						<input type="text" class="form-control" v-model="name" name="flight-desc" placeholder="e.g. First flight" autofocus>
						<span class="invalid-feedback"></span>
						<p class="form-text text-muted small mb-0">Optional. A unique description for this flight.</p>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Date</label>
								<input type="date" class="form-control pe-3" v-model="date" :max="dateMax" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Time</label>
								<input type="time" class="form-control pe-3" v-model="time" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<hr class="mt-2 mb-4" />
					<h2>Launch site</h2>
					<div class="form-group">
						<label class="form-label">Name</label>
						<input type="text" class="form-control" v-model="location" placeholder="e.g. Cape Canaveral">
						<span class="invalid-feedback"></span>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="launchLatitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="launchLongitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pt-4 ps-0">
							<button @click.prevent="geolocateLaunch" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					
					<div class="form-text text-muted small mb-4">Use the Geolocate button in the field for exact coordinates.</div>

					<hr class="mt-2 mb-4"/>
					<h2>Configuration</h2>
					<div class="row">
						<div class="col-9">
							<div class="form-group">
								<label class="form-label">
									Motor(s)
								</label>
								<input type="text" class="form-control" v-model="motors" placeholder="e.g. G80SK" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-3">
							<div class="form-group">
								<label class="form-label">
									Delay
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="240" step="1" autocomplete="off" v-model="delay" class="form-control" placeholder="e.g. 7" :required="mapFlight" />
									<div class="input-group-append">
										<div class="input-group-text">
											s
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Lift-off mass
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="mass" class="form-control" placeholder="e.g. 1100" />
									<div class="input-group-append">
										<div class="input-group-text">
											g
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Stability margin
								</label>
								<input type="number" class="form-control" v-model="sm" min="0" max="100" step="0.1" placeholder="e.g. 1.1">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					
					<div class="form-group">
						<label class="form-label mb-1">Notes</label>
						<textarea class="form-control" v-model="notes" rows="4"></textarea>
					</div>
					<hr class="my-5" />
					<h2>Outcome</h2>

					<div class="form-group">
						<label class="form-label">Flight result</label>
						<select :required="mapFlight" ref="outcome" v-model="outcome" class="form-control">
							<option value="">Not flown yet</option>
							<option value="positive">Positive</option>
							<option value="neutral">Neutral</option>
							<option value="negative">Negative</option>
						</select>
						<span class="invalid-feedback"></span>
						<span class="form-text text-muted small mb-4">Was the flight a success?</span>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="landingLatitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="landingLongitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pt-4 ps-0">
							<button @click.prevent="geolocateLanding" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					<div class="form-text text-muted small mb-4">Use the Geolocate button in the field for exact coordinates.</div>

					<div class="form-group">
						<label>Flight logs</label>
						<div class="form-control form-control-dropzone">
							<div ref="dzLogs" class="dropzone dropzone-multiple dz-clickable" @click="uploadClick" @drop="dropped" @dragover="dragover">
								<input type="file" @change="flightLogAdded" ref="fupFlightLogs" multiple />
								<div class="dz-default dz-message">
									<button class="dz-button" type="button"><span class="fe fe-plus"></span> Add flight log CSVs</button>
								</div>
							</div>
							<div v-if="flightLogError" class="text-danger small mt-2">{{ flightLogError }}</div>
							<div class="list-group list-group-flush pt-4 my-n3">
								<div v-for="log of flightLogs" class="list-group-item">
									<div class="row align-items-center">
										<div class="col ms-n2">
											<h4 class="fw-normal mb-1">
												{{ log.name }}
											</h4>
										</div>
										<div class="col-auto">
											<button class="text-danger" type="button" @click="removeLog(log)"><span class="fe fe-x"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p class="form-text text-muted small mb-0">This feature is still under development, if your flight log isn't supported please <a href="mailto:membership@ukra.org.uk">send it to us</a>.</p>
					</div>
					<hr class="my-5" />
					<h2>Model Achievement Programme</h2>
					<div v-if="user.map.attemptPending" class="alert alert-light small mb-4"><span class="fe fe-info"></span> You currently have a pending MAP attempt, please wait for UKRA to approve it before submitting another.</div>
					<div class="form-group">
						<div class="custom-control custom-switch">
							<input type="checkbox" class="custom-control-input" :value="true" v-model="mapFlight" :disabled="user.map.attemptPending">
							<label class="custom-control-label">This is a MAP flight</label>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label">
							MAP objectives
						</label>
						<select name="map" v-model="mapTasks" ref="mapTasks" class="form-control" :disabled="!mapFlight" required></select>
						<span class="invalid-feedback mt-2 d-block"></span>
						<p class="form-text text-muted small mb-0">This must match the flight card.</p>
					</div>
					<div class="field form-group">
						<label>
							Upload a picture of the signed flight card
							<div class="dz-default dz-message py-3" v-if="flightCard">
								<div class="avatar avatar-xxl">
									<img ref="imgAvatar" class="avatar-img" :src="flightCard" alt="Flight Card">
								</div>
							</div>
						</label>
						<div class="custom-file">
							<input type="file" @change="flightCardAdded" :disabled="!mapFlight" ref="fupFlightCard" class="custom-file-input" name="flight-card" accept="image/*;capture=camera">
							<label ref="lblFlightCard" class="custom-file-label text-muted">Choose image&hellip;</label>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text text-muted small mb-0">Must be a jpeg or png.</span>
					</div>
					<button class="btn w-100 btn-primary" v-if="!mapFlight">Add flight <span class="fe fe-check-circle"></span></button>
					<button class="btn w-100 btn-success" v-if="mapFlight">Submit flight <span class="fe fe-check-circle"></span></button>
					<span class="form-text text-muted text-center small mb-0" v-if="mapFlight">This flight will not be editable after submitting.</span>
				</form>
			</div>
		</div>
    </div>
	
	<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="card mb-0">
					<div class="card-header">
						<h4 class="card-header-title">Crop the image</h4>
						<button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="card-body">
						<p>Your picture must be good quality and clearly legible.</p>
						<div class="img-container">
							<img ref="imgCropper" :src="flightCard" class="">
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
						<button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
