<script>
export const pageName = 'tools-launch-sites';
export const pageRoute = '/tools/launch-sites';
export const title = 'Lauch Sites'

import { Loader } from "@googlemaps/js-api-loader"
import { toRaw } from "vue";
import noFlyZones from './no-fly-zones.json'
import rocketNotams from './rocket-notams.json'

for(let i = 0; i < noFlyZones.length; i++)
{
	let zone = noFlyZones[i];
	zone.path = zone.path.map(e => ({ lat: e[1], lng: e[0]}))
}

let clubs = [
	{ 
		name: 'Midland Rocketry Club',
		url: 'https://www.midlandrocketry.org.uk/',
		center: { lat: 52.6688015, lng: -1.5247266 }
	},
	{
		name: 'East Anglian Rocketry Society',
		url: 'https://ears.org.uk/',
		center: { lat: 52.2531495, lng: -0.0914776}
	},
	{
		name: 'Scottish Aeronautics and Rocketry Association',
		url: 'https://www.sara.rocketry.org.uk/',
		center: { lat: 55.7265293, lng: -4.8120324}
	}
]

for(let i = 0; i < rocketNotams.length; i++)
{
	let notam = rocketNotams[i];
	notam.path = notam.path.map(e => ({ lat: e[1], lng: e[0]}))

    var x = notam.path.map(p => p.lat);
    var y = notam.path.map(p => p.lng);
    var cx = (Math.min(...x) + Math.max(...x)) / 2;
    var cy = (Math.min(...y) + Math.max(...y)) / 2;
    notam.center = { lat: cx, lng: cy}

	notam.from = new Date(notam.from)
	notam.to = new Date(notam.to)

	notam.club = clubs.find(c => pointIsInPoly(c.center, notam.path));
}


function pointIsInPoly(p, polygon) {
    var isInside = false;
    var minX = polygon[0].lng, maxX = polygon[0].lng;
    var minY = polygon[0].lat, maxY = polygon[0].lat;
    for (var n = 1; n < polygon.length; n++) {
        var q = polygon[n];
        minX = Math.min(q.lng, minX);
        maxX = Math.max(q.lng, maxX);
        minY = Math.min(q.lat, minY);
        maxY = Math.max(q.lat, maxY);
    }

    if (p.lng < minX || p.lng > maxX || p.lat < minY || p.lat > maxY) {
        return false;
    }

    var i = 0, j = polygon.length - 1;
    for (i, j; i < polygon.length; j = i++) {
        if ( (polygon[i].lat > p.lat) != (polygon[j].lat > p.lat) &&
                p.lng < (polygon[j].lng - polygon[i].lng) * (p.lat - polygon[i].lat) / (polygon[j].lat - polygon[i].lat) + polygon[i].lng ) {
            isInside = !isInside;
        }
    }

    return isInside;
}

export default {
	data() {
		return { 
			toggles: [
				false,
				false,
				true,
				true,
				true,
				true,
				false,
				false
			],
			maxAltM: 500,
			maxAltF: 1640
		}
	},

	methods: {
		async boot ()
		{
			this.loader = new Loader({
				apiKey: "AIzaSyARm95zbXVIPPB3qO9adjXN5ewAc94waHU",
				version: "weekly",
			});

			this.distances = [
				{
					label: "Upto 2.5 Ns (A)",
					radius: 30 / 2,
					visible: false
				},
				{ 
					label: "2.5 - 5 Ns (B)",
					radius: 60 / 2,
					visible: false
				},
				{ 
					label: "5 - 10 Ns (C)",
					radius: 120 / 2,
					visible: true
				},
				{ 
					label: "10 - 20 Ns (D)",
					radius: 150 / 2,
					visible: true
				},
				{ 
					label: "20 - 160 Ns (E - G)",
					radius: 300 / 2,
					visible: true
				},
				{ 
					label: "160 - 10,240 Ns (H - M)",
					radius: 450 / 2,
					visible: true
				},
				{ 
					label: "10,240 - 20,480 Ns (N)",
					radius: 600 / 2,
					visible: false
				},
				{ 
					label: "24,480 - 40,960 Ns (O)",
					radius: 900 / 2,
					visible: false
				}
			]

		},
		show() {
			this.loader.load().then(async () => {
								
				class LabelOverlay extends google.maps.OverlayView {
					center = null
					distance = null
					div = null

					constructor(center, distance) {
						super();
						this.center = center;
						this.distance = distance;
						this.zIndex = 99;
					}
					
					onAdd() {
						this.div = document.createElement("div");
						this.div.style.borderStyle = "none";
						this.div.style.borderWidth = "0px";
						this.div.style.position = "absolute";
						this.div.innerText = this.distance.label

						const panes = this.getPanes();
						panes.overlayLayer.appendChild(this.div);
					}
					draw() {
						this.div.style.fontSize = "1.25em";
						// this.div.style.textShadow = "0 0 1px #000, 0 0 2px #000, 0 0 3px #000";
						this.div.style["-webkit-text-stroke"] = "3px #000"
						this.div.style["text-stroke"] = "1px #000"
						this.div.style["paint-order"] = "stroke fill"
						this.div.style.color = "#fff";
						this.div.style.transform = "translateX(-50%)";
						this.setCenter(this.center);
						this.setVisible(this.distance.visible)
					}
					setCenter(center)
					{
						this.center = center;
						const overlayProjection = this.getProjection();
						const c = overlayProjection.fromLatLngToDivPixel(this.center);
						this.div.style.left = c.x + "px";
						this.div.style.top = (c.y - 20) + "px";
					}
					setVisible(visible) {
						this.visible = visible
						this.div.style.visibility = visible ? "visible" : 'hidden';
					}
					setLabel(label) {
						this.div.innerText = label
					}
				}

				const { Map } = await google.maps.importLibrary("maps");
				this.spherical = (await google.maps.importLibrary("geometry")).spherical

				const params = new URLSearchParams(window.location.search);
				let centerLat = parseFloat(params.get('centerX'));
				let centerLng = parseFloat(params.get('centerY'))

				this.center = !isNaN(centerLat) && !isNaN(centerLng) ? { lat: centerLat, lng: centerLng} : { lat: 52.6688015, lng: -1.5247266 };

				this.map = new Map(this.$refs.map, {
					center: this.center,
					zoom: 16,
					disableDoubleClickZoom: true,
					mapTypeId: 'hybrid',
					options: {
						gestureHandling: 'greedy'
					}
				});

				this.map.addListener("click", (e) => {
					this.infowWindowTimeout = setTimeout(() => {
						if(this.infowindow)
						{
							this.infowindow.close();
							this.infowindow = null;
						}

						let point = { lat: e.latLng.lat(), lng: e.latLng.lng() }

						let zones = noFlyZones.filter(z => pointIsInPoly(point, z.path))
						let notams = rocketNotams.filter(n => pointIsInPoly(point, n.path));

						if(notams.length == 0 && zones.length == 0)
							return

						let content = (notams.length ? notams.map(z => `<h3>NOTAM: ${z.name}</h3><p>${z.active}<br/><br/>${z.affects}<br /><br/>${z.notam.replace('\n', '<br/ >')}</p>`).join('') : '')
									+ zones.map(z => `<h3>${z.name}</h3><p>${z.type == 'restrict' ? 'Restricted' : z.type == 'control' ? 'Controlled' : 'Danger'}<br/><br/>${z.affects}</p>`).join('');

						this.infowindow = new google.maps.InfoWindow({
							content,
							ariaLabel: "Uluru",
							position: point
						});
						this.infowindow.open({
							map: this.map,
						});
					}, 100)
				});

				this.map.addListener("dblclick", (e) => {
					clearTimeout(this.infowWindowTimeout)
					this.center = { lat: e.latLng.lat(), lng: e.latLng.lng() }
					this.redraw();
				});
				
				this.altCircle = new google.maps.Circle({
					strokeColor: "#FF00FF",
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillOpacity: 0,
					visible: true,
					map: this.map,
					center: this.center,
					radius: this.maxAltM * 0.5,
					clickable: false,
					draggable: false,
					editable: false,
					zIndex: 98
				});

				var moved = this.spherical.computeOffset(this.center, this.maxAltM * 0.5, 0);
				this.altCircleLabel = new LabelOverlay(moved, { radius: this.maxAltM * 0.5, label: `Ballistic range from ${this.maxAltM}m/${this.maxAltF}ft`, visible: true});
				this.altCircleLabel.setMap(this.map);

				for(let i = 0; i < this.distances.length; i++)
				{
					let distance = this.distances[i];

					distance.mapCircle = new google.maps.Circle({
						strokeColor: "#0000FF",
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillOpacity: 0,
						visible: distance.visible,
						map: this.map,
						center: this.center,
						radius: distance.radius,
						clickable: false,
						draggable: false,
						editable: false,
						zIndex: 98
					});

					var moved = this.spherical.computeOffset(this.center, distance.radius, 0);
					this.distances[i].mapCircleLabel = new LabelOverlay(moved, distance);
					this.distances[i].mapCircleLabel.setMap(this.map);
				}
				
				for(let i = 0; i < noFlyZones.length; i++)
				{
					let zone = noFlyZones[i];

					let poly = new google.maps.Polygon({
						paths: zone.path,
						strokeColor: zone.type == 'restrict' ? "#FF0000" : zone.type == 'control' ? '#FFFF00' : zone.type == 'danger' ? '#00ffff' : '#000',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor:  zone.type == 'restrict' ? "#FF0000" : zone.type == 'control' ? '#FFFF00' : zone.type == 'danger' ? '#00ffff' : '#000',
						fillOpacity: 0.30,
						visible: true,
						clickable: false,
						draggable: false,
						editable: false,
						zIndex: 98
					})
					poly.setMap(this.map)
				}

				for(let i = 0; i < rocketNotams.length; i++)
				{
					let notam = rocketNotams[i];
					
					let poly = new google.maps.Polygon({
						paths: notam.path,
						strokeColor: '#0F0',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor:  '#0F0',
						fillOpacity: 0.30,
						visible: true,
						clickable: false,
						draggable: false,
						editable: false,
						zIndex: 98
					})
					poly.setMap(this.map)
				}
			});
		},
		m2f() {
			this.maxAltF = Math.floor(this.maxAltM * 3.28084);
			this.redraw();
		},
		f2m() {
			this.maxAltM = Math.floor(this.maxAltF * 0.3048);
			this.redraw();
		},
		redraw() {
			for(let i = 0; i < this.distances.length; i++)
			{
				let distance = this.distances[i]
				
				distance.visible = toRaw(this.toggles[i])

				distance.mapCircle.setOptions({
					center: this.center,
					visible: distance.visible
				})
			
				var moved = this.spherical.computeOffset(this.center, distance.radius, 0);
				this.distances[i].mapCircleLabel.setCenter(moved)
				this.distances[i].mapCircleLabel.setVisible(distance.visible)
			}

			this.altCircle.setOptions({
				center: this.center,
				radius: this.maxAltM * 0.5
			})
		
			var moved = this.spherical.computeOffset(this.center, this.maxAltM * 0.5, 0);
			this.altCircleLabel.setCenter(moved)
			this.altCircleLabel.setLabel(`Ballistic range from ${this.maxAltM}m/${this.maxAltF}ft`)
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 my-5">
				<h1 class="display-4 text-center mb-3">Launch Site Finder</h1>
				<p class="text-muted text-center mb-5">See what the maximum motor is you can launch from your flying site, and where restricted air space is.</p>
				<p>Scroll around the map to locate your flying site.</p>
				<p>Double click the map to set the launch pad location.</p>
				<p>Use the maximum-altitude input and the motor class checkboxes to show the minimum disperal radius indicators. Note that the larger of the motor-class and your expected max-altitude is the minimum flying site dimension you should use.</p>
				
				<form action="" @submit.prevent="">
					<div class="row">
						<div class="col">
							<div class="form-group">
								<label class="form-label">
									Maximum Altitude:
								</label>
								<div class="input-group has-validation">
									<input type="number" min="10" max="99999" step="1" autocomplete="off" @change="m2f" v-model="maxAltM" class="form-control" placeholder="e.g. 500" />
									<div class="input-group-append">
										<div class="input-group-text">
											m
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label class="form-label">
									Maximum Altitude:
								</label>
								<div class="input-group has-validation">
									<input type="number" min="10" max="99999" step="1" autocomplete="off" @change="f2m" v-model="maxAltF" class="form-control" placeholder="e.g. 500" />
									<div class="input-group-append">
										<div class="input-group-text">
											ft
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>
					<p>Motor classes:</p>
					<div class="row">
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[0]" @change="redraw">
								<label class="custom-control-label d-block">Upto 2.5 Ns (A)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[1]" @change="redraw">
								<label class="custom-control-label">2.5 - 5 Ns (B)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[2]" @change="redraw">
								<label class="custom-control-label">5 - 10 Ns (C)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[3]" @change="redraw">
								<label class="custom-control-label">10 - 20 Ns (D)</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[4]" @change="redraw">
								<label class="custom-control-label">20 - 160 Ns (E - G)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[5]" @change="redraw">
								<label class="custom-control-label">160 - 10,240 Ns (H - M)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[6]" @change="redraw">
								<label class="custom-control-label">10,240 - 20,480 Ns (N)</label>
							</div>
						</div>
						<div class="form-group col">
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" :value="true" v-model="toggles[7]" @change="redraw">
								<label class="custom-control-label">24,480 - 40,960 Ns (O)</label>
							</div>
						</div>
					</div>
				</form>
				<p class="text-center small text-muted">Restricted, Controlled, Danger zone and NOTAM information provided by NATS. We make every effort to ensure this is correct but please check with NATS for 100% accurate information.</p>
				<p>Key: <span class="fe fe-square text-danger"></span> Restricted Airspace <span class="fe fe-square text-warning"></span> Controlled Airspace, <span class="fe fe-square" style="color: aqua"></span> Danger Airspace, <span class="fe fe-square text-success"></span> Rocket-related NOTAM</p>
			</div>
		</div>
    </div>
	<div ref="map" class="mx-5" style="height: 75vh"></div>
</template>

