<script>
export const pageName = 'tools-notam-list';
export const pageRoute = '/tools/notam-list';
export const title = 'Lauch Sites'

import rocketNotams from './rocket-notams.json'
import ukraLogo from '../../img/ukra-logo.png';

export default {
	data() {
		
		let list = [...rocketNotams];
		list.sort((a, b) => a.from.getTime() - b.from.getTime())

		return { 
			rocketNotams: list,
			ukraLogo
		}
	},

	methods: {
		async boot ()
		{
			

		},
		show() {
			
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 my-5">
				<h1 class="display-4 text-center mb-3">NOTAM List</h1>
				<p class="text-muted text-center">See what current and upcoming rocket-related NOTAMS are in place.</p>
				<p class="text-muted text-center mb-5">Please note that these are not necesarilly public events you can invite yourself to. UKRA events are marked accordingly.</p>

				<div class="card" v-for="notam in rocketNotams">
					<div class="card-header">
						<h4 v-if="!notam.club" class="card-header-title">{{ notam.name }}</h4>
						<a v-if="notam.club" :href="notam.club.url" target="_blank"><h4 class="card-header-title">{{ notam.club.name }} <span class="fe fe-external-link"></span></h4></a>
						<img v-if="notam.club" class="avatar avatar-xs" :src="ukraLogo" />
					</div>
					<div class="card-body">
						<p><span class="fe fe-clock text-primary"></span> {{ notam.active }}</p>
						<p><span class="fe fe-arrow-up text-primary"></span> {{ notam.affects }}</p>
						<p><span class="fe fe-file-text text-primary"></span> {{ notam.notam }}</p>
						<a :href="`/tools/launch-sites?centerX=${notam.center.lat}&centerY=${notam.center.lng}`" class="btn btn-sm btn-primary">View on map <span class="fe fe-map-pin"></span></a>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
